.bg-gray-50 {
  background-color: #f9fafb;
}
.bg-brown-50 {
  background-color: #fffbeb;
}

.bg-brown-100 {
  background-color: #fef3c7;
}
.bg-blue-50 {
  background-color: #eef2ff;
}

.bg-blue-75 {
  background-color: #e3eefc;
}
.right-2 {
  right: 2rem;
}
.left-drawer {
  left: -35vh;
}
.w-28 {
  width: 7.5rem;
}
.w-36 {
  width: 9.5rem;
}
.w-35vh {
  width: 35vw;
}
.w-65vh {
  width: 65vw;
}
.w-70vw {
  width: 70vw;
}
.z-5 {
  z-index: 5;
}
.z-6 {
  z-index: 6;
}
.height-0 {
  --transform-scale-y: 0;
}
.height-50 {
  --transform-scale-y: 0.5;
}
.height-100 {
  --transform-scale-y: 1;
}
.text-xxs {
  font-size: 0.65rem;
}
.text-xxxs {
  font-size: 0.4rem;
}
.child {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 730px) {
  .daterangepicker {
    width: 245px !important;
    position: absolute !important;
    /* top: 100px !important;
    left: 50% !important;
    transform: translate(-50%, -10px) !important; */
    z-index: 30 !important;
  }
  .drp-buttons {
    justify-content: flex-center !important;
  }
  .ranges {
    width: 100% !important;
  }
}
ul {
  width: 100% !important;
}

.drp-calendar {
  border-style: none !important;
}

@media only screen and (min-width: 731px) {
  .daterangepicker {
    max-width: 650px !important;
    position: absolute !important;
    /* top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important; */
    z-index: 30 !important;
  }
  .ranges {
    width: 150px !important;
  }
  .drp-buttons {
    justify-content: flex-end !important;
  }
}
/* .drp-buttons {
  width: 300px;
} */

.Data-View {
  width: 80vw;
  position: fixed;
  top: 10vh;
  left: 10vw;
}
.Mobile-View {
  position: fixed;
  top: 10vh;
  left: 50%;
  transform: translate(-50%, 10vh);
}

.top--5 {
  top: 10;
}
.tooltip {
  position: relative;
  display: inline-block;
}
.react-datepicker-popper {
  z-index: 60 !important;
}
.tooltip .tooltiptext {
  visibility: hidden;
  right: 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 50;
}

.opacityBackground {
  opacity: 0.8;
}

.top-20 {
  top: 20vh;
}
.thSticky {
  position: sticky;
  top: 0;
  z-index: 1;
}

.pl-1half {
  padding: 0.4rem;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.h-70vh {
  height: 70vh;
}
.wrd-Break {
  word-wrap: break-word;
}
.w-80per {
  width: 80vw;
}
.w-70per {
  width: 70vw;
}
.w-80percent {
  width: 80%;
}

.w-30per {
  width: 350px;
}
.h-80per {
  height: 600px;
}
.h-60per {
  height: 60vh;
}
.h-60percent {
  height: 60%;
}
.h-80percent {
  height: 80%;
}

.mobile {
  border: 2px solid gray;
  padding: 2px;
  border-radius: 50px;
}
.rounded-2xl {
  border-radius: 30px;
}
